const env = String(process?.env?.ENV);
const brand = String(process.env.BRAND);

export const Config = {
	Env: {
		Is: env,
		IsLocal: env === 'local',
		IsDev: env === 'dev',
		IsQa: env === 'qa',
		IsStg: env === 'stg',
		IsPreview: env === 'preview',
		IsPrd: env === 'prd',
	},
	SitecoreApiKey: String(process?.env?.SITECORE_API_KEY),
	SitecoreGraphQLEndpoint: String(process?.env?.GRAPH_QL_ENDPOINT),
	Urls: {
		Cms: String(process.env.CMS_URL),
		Order: String(process.env.ORDER_URL),
		OrderRaw: String(process.env.ORDER_URL_RAW),
		CateringRaw: String(process.env.CATERING_URL_RAW),
		Olo: String(process.env.OLO_URL),
		Bbi: String(process.env.BBI_URL),
	},
	Brand: brand,
	RevalidationPeriod: Number(process.env.REVALIDATION_PERIOD),
	Auth0: {
		domain: String(process.env.AUTH0_DOMAIN),
		clientId: String(process.env.AUTH0_CLIENT_ID),
		redirectUri: String(process.env.AUTH0_LOGIN_REDIRECT_URI),
		audience: String(process.env.AUTH0_AUDIENCE_URI),
		redirectUriCms: String(process.env.AUTH0_LOGIN_REDIRECT_URI_CMS),
	},
	Source: String(process?.env?.SOURCE),
	BbiKey: String(process?.env?.BBI_SUBSCRIPTION_KEY),
} as const;
